import { WordPressBlock } from '@faustwp/blocks';
import { AcfStepDisplay as AcfStepDisplayProps } from '../../__generated__/graphql-old';
import StepDisplay from '../../components/StepDisplay';
import { gql } from '@apollo/client';

const AcfStepDisplay: WordPressBlock<AcfStepDisplayProps> = props => {
	const stepDisplayProps = {
		title: props.blockStepDisplay?.title,
		heading: props.blockStepDisplay?.heading,
		content: props.blockStepDisplay?.content,
		variant: props.blockStepDisplay?.variant,
	};
	return <StepDisplay {...stepDisplayProps} />;
};

AcfStepDisplay.fragments = {
	key: `AcfStepDisplayBlockFragment`,
	entry: gql`
		fragment AcfStepDisplayBlockFragment on AcfStepDisplay {
			blockStepDisplay {
				heading
				title
				variant
				content
			}
		}
	`,
};

AcfStepDisplay.displayName = 'AcfStepDisplay';

export default AcfStepDisplay;
