import { WordPressBlock } from '@faustwp/blocks';
import { AcfProductIconDisplay as AcfGeneratedProductIconDisplayProps } from '../../__generated__/graphql-old';
import { propsObjectType } from '../../types/declarations';
import { AcfProductIconDisplayProps } from '../../types/AcfProductIconDisplay';
import ProductIconDisplay from '../../components/ProductIconDisplay';

const AcfProductIconDisplay: WordPressBlock<
	AcfGeneratedProductIconDisplayProps
> = props => {
	const image = props.blockProductIconDisplay.icon;

	const productIconProps: AcfProductIconDisplayProps = {
		title: props.blockProductIconDisplay.title,
		icon: {
			sourceUrl: image.node.sourceUrl,
			sizes: image.node.sizes,
			altText: image.node.altText,
			srcSet: image.node.srcSet,
			mediaDetails: {
				width: image.node.mediaDetails.width,
				height: image.node.mediaDetails.height,
			},
		},
		content: props.blockProductIconDisplay.content,
		link:
			props.blockProductIconDisplay.pageLink?.nodes.length > 0
				? props.blockProductIconDisplay.pageLink.nodes[0].link
				: '',
	};
	return <ProductIconDisplay {...productIconProps} />;
};

AcfProductIconDisplay.displayName = 'AcfProductIconDisplay';

export default AcfProductIconDisplay;
