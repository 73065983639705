import { gql } from '../../__generated__';
import { WordPressBlock, getStyles, useBlocksTheme } from '@faustwp/blocks';
import { CoreParagraphFragmentFragment } from '../../__generated__/graphql-old';
import { Box } from '@chakra-ui/react';
import { propsObjectType } from '../../types/declarations';
import { noto_sans } from '../../fonts';

const CoreParagraph: WordPressBlock<CoreParagraphFragmentFragment> = props => {
	// console.log('Paragraph Props', props);
	const theme = useBlocksTheme();
	const style = getStyles(theme, { ...props });
	const { attributes } = props;

	const pProps: propsObjectType = {};

	if (!style?.marginTop || !style.marginBottom) {
		pProps.my = 2;
	}

	return (
		<Box
			as={'p'}
			{...pProps}
			style={style}
			id={attributes?.anchor}
			className={attributes?.cssClassName}
			fontFamily={noto_sans.style.fontFamily}
			dangerouslySetInnerHTML={{ __html: attributes?.content ?? '' }}
		/>
	);
};

CoreParagraph.fragments = {
	entry: gql(`
      fragment CoreParagraphFragment on CoreParagraph {
        attributes {
          content
          cssClassName
          style
          fontSize
          fontFamily
          textColor
          backgroundColor
          align
          anchor
          gradient
        }
      }
    `),
	key: `CoreParagraphFragment`,
};

CoreParagraph.displayName = 'CoreParagraph';

export default CoreParagraph;
