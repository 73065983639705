import {
	Box,
	Button,
	Container,
	Heading,
	Stack,
	VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useSiteStore } from '../../hooks/use-site-store';
import { useBlocksTheme } from '@faustwp/blocks';
import { CBATheme } from '../../types/Theme';
import { GetPostQuery } from '../../__generated__/graphql-old';
import Image from 'next/image';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { formatDate } from '../../support/helpers';
import Link from 'next/link';

type PostPageBannerProps = {
	date: GetPostQuery['post']['date'];
	content?: GetPostQuery['post']['postSettings']['topContent'];
	featuredImage?: GetPostQuery['post']['featuredImage']['node'];
};

const PostPageBanner: FC<PostPageBannerProps> = ({
	date,
	content,
	featuredImage,
}) => {
	const { pageTitle } = useSiteStore(state => state);
	const theme = useBlocksTheme() as unknown as CBATheme;
	return (
		<Box mb={12}>
			<Box bg={theme.palette.accent}>
				<Container>
					<Stack
						direction={{ base: 'column', lg: 'row' }}
						gap={{
							base: 4,
							lg: 12,
						}}
						align="center"
					>
						<Box as="figure" flex="1">
							<Image
								src={featuredImage.sourceUrl}
								alt={featuredImage.altText}
								width={featuredImage.mediaDetails.width}
								height={featuredImage.mediaDetails.height}
								priority={true}
							/>
						</Box>
						<Box
							px={{
								base: 0,
								lg: 8,
							}}
							pb={{
								base: 4,
								lg: 0,
							}}
							flex="1"
							dangerouslySetInnerHTML={{ __html: content }}
							sx={{
								p: {
									fontSize: {
										base: theme.fontSizes.small,
										lg: '1.25rem',
									},
								},
							}}
						/>
					</Stack>
				</Container>
			</Box>
			<Container>
				<Button
					leftIcon={<ChevronLeftIcon />}
					bg={theme.palette.secondary}
					color="white"
					borderTopRadius="none"
					_hover={{
						bg: theme.palette.primary,
					}}
					as={Link}
					href={'/resources/articles/'}
				>
					Back to Articles
				</Button>
			</Container>
			<VStack
				textAlign="center"
				align="center"
				mt={12}
				gap={{ base: 6, lg: 12 }}
			>
				<Heading color={theme.palette.primary} fontWeight="400" as="h1">
					{pageTitle}
				</Heading>
				<Box color={theme.palette.secondary} fontWeight="bold">
					{formatDate(date)}
				</Box>
			</VStack>
		</Box>
	);
};

export default PostPageBanner;
