import { WordPressBlock } from '@faustwp/blocks';
import { AcfCountupDisplay as AcfCountupDisplayProps } from '../../__generated__/graphql-old';
import { CountupDisplayProps } from '../../types/CountUpDisplay';
import CountupDisplay from '../../components/CountupDisplay';

const AcfCountupDisplay: WordPressBlock<AcfCountupDisplayProps> = props => {
	// console.log('Count Up Props', props.blockCountUpDisplay);
	const countupProps: CountupDisplayProps = {
		string: props.blockCountUpDisplay.string,
		number: props.blockCountUpDisplay.number,
		smallFontSizeForString:
			props.blockCountUpDisplay.smallFontSizeForString,
		fontColor: props.blockCountUpDisplay.fontColor,
		isStringBeforeText: props.blockCountUpDisplay.isStringBeforeText,
		noCount: false,
	};

	return <CountupDisplay {...countupProps} />;
};

AcfCountupDisplay.displayName = 'AcfCountupDisplay';

export default AcfCountupDisplay;
