import { gql, useQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Container,
	Heading,
	HStack,
	SimpleGrid,
	Skeleton,
	Stack,
	VStack,
} from '@chakra-ui/react';
import { useBlocksTheme } from '@faustwp/blocks';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { GetArchiveQuery } from '../../__generated__/graphql-old';
import { frontendLink } from '../../support/helpers';
import { CBATheme } from '../../types/Theme';
import PostBanner from './components/PostBanner';
import PostCard from './components/PostCard';
import _ from 'lodash';

const GET_POSTS = gql`
	query getPosts($first: Int!, $after: String, $before: String) {
		posts(first: $first, after: $after, before: $before) {
			nodes {
				date
				excerpt
				id
				link
				title
				featuredImage {
					node {
						id
						sourceUrl
						altText
						mediaDetails {
							width
							height
						}
						sizes(size: MEDIUM)
					}
				}
				categories {
					nodes {
						categoryId
						name
					}
				}
				tags {
					nodes {
						name
						link
						id
					}
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}
`;

const BATCH_SIZE = 8;

type PostResponseProps = {
	posts: GetArchiveQuery['posts'];
};

const PostFeed: FC<{
	blogSettings: GetArchiveQuery['page']['blogSettings'];
	postTotal: GetArchiveQuery['postTotal'];
}> = ({ blogSettings, postTotal }) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	// const [postStart, setPostStart] = useState(0);

	const [posts, setPosts] = useState<GetArchiveQuery['posts']['nodes']>([]);
	const [hasBefore, setHasBefore] = useState(false);
	const [hasAfter, setHasAfter] = useState(false);

	const { data, loading, error, fetchMore } = useQuery<PostResponseProps>(
		GET_POSTS,
		{
			variables: { first: BATCH_SIZE, after: null, before: null },
		}
	);

	useEffect(() => {
		if (data?.posts?.nodes) {
			setPosts(data.posts.nodes);

			setHasAfter(Boolean(data?.posts?.pageInfo?.hasNextPage));
			setHasBefore(Boolean(data?.posts?.pageInfo?.hasPreviousPage));
		}
	}, [data]);

	function handleLoadMorePost() {
		fetchMore({
			variables: {
				after: data.posts.pageInfo.startCursor,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				if (!fetchMoreResult) return previousResult;

				// Combine the previous and the new posts
				return {
					posts: {
						...fetchMoreResult.posts,
						nodes: [...fetchMoreResult.posts.nodes],
					},
				};
			},
		});
	}

	function handleBackPost() {
		fetchMore({
			variables: {
				before: data.posts.pageInfo.endCursor,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				if (!fetchMoreResult) return previousResult;

				// Combine the previous and the new posts
				return {
					posts: {
						...fetchMoreResult.posts,
						nodes: [...fetchMoreResult.posts.nodes],
					},
				};
			},
		});
	}

	const LoadMoreButtons: FC = () => {
		return (
			<Stack
				my={6}
				gap={8}
				direction={{
					base: 'column',
					lg: 'row',
				}}
				px={{ base: 0, lg: 8 }}
			>
				<HStack
					gap={8}
					justify={{
						base: 'center',
						lg: 'space-between',
					}}
				>
					<Box>
						<Button
							variant="outline"
							colorScheme="black"
							onClick={handleBackPost}
							isLoading={loading}
							leftIcon={<ChevronLeftIcon />}
							isDisabled={!hasBefore}
						>
							Load Previous
						</Button>
					</Box>
					<Box>
						<Button
							variant="outline"
							colorScheme="black"
							onClick={handleLoadMorePost}
							isLoading={loading}
							rightIcon={<ChevronRightIcon />}
							isDisabled={!hasAfter}
						>
							Load Next
						</Button>
					</Box>
				</HStack>
				<Box
					textAlign={{
						base: 'center',
						lg: 'right',
					}}
					flex="1"
				>{`Showing ${posts.length} of ${postTotal} posts`}</Box>
			</Stack>
		);
	};

	const PostSkeleton: FC = () => <Skeleton h="421px" rounded="lg" />;

	return (
		<VStack align="stretch" position="relative">
			<Box
				position="absolute"
				bgGradient="linear(to-b, gray.200, transparent)"
				h="400px"
				left="0"
				right="0"
				zIndex="0"
			/>
			<PostBanner />

			<Container my={4} position="relative" zIndex="5">
				<LoadMoreButtons />
				<SimpleGrid
					columns={{ base: 2, lg: 3 }}
					gap={{
						base: 4,
						lg: 8,
					}}
					px={{ base: 0, lg: 8 }}
				>
					{blogSettings.adImage?.node && (
						<Box
							overflow="hidden"
							sx={{
								img: {
									maxW: '100%',
								},
							}}
						>
							<Image
								src={blogSettings.adImage.node.sourceUrl}
								alt={blogSettings.adImage.node.altText}
								width={
									blogSettings.adImage.node.mediaDetails.width
								}
								height={
									blogSettings.adImage.node.mediaDetails
										.height
								}
								style={{
									borderRadius: '20px',
								}}
							/>
						</Box>
					)}
					{!loading &&
						posts &&
						posts.map(post => (
							<PostCard key={post.id} post={post} />
						))}
					{loading && _.range(0, 8).map(i => <PostSkeleton />)}
					{blogSettings.ctaTitle && (
						<CtaBox
							title={blogSettings.ctaTitle}
							content={blogSettings.ctaContent}
							buttonLabel={blogSettings.ctaButtonLabel}
							buttonLink={blogSettings.ctaButtonUrl.nodes[0].link}
							display="desktop"
						/>
					)}
				</SimpleGrid>
				<LoadMoreButtons />
				{blogSettings.ctaTitle && (
					<CtaBox
						title={blogSettings.ctaTitle}
						content={blogSettings.ctaContent}
						buttonLabel={blogSettings.ctaButtonLabel}
						buttonLink={blogSettings.ctaButtonUrl.nodes[0].link}
						display="mobile"
					/>
				)}
			</Container>
		</VStack>
	);
};

type CtaBoxProps = {
	title: string;
	content: string;
	buttonLink: string;
	buttonLabel: string;
	display: 'desktop' | 'mobile';
};

const CtaBox: FC<CtaBoxProps> = ({
	title,
	content,
	buttonLabel,
	buttonLink,
	display,
}) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	return (
		<VStack
			align="stretch"
			justifyContent="center"
			textAlign="center"
			border="3px"
			borderStyle="solid"
			borderRadius="20px"
			borderColor="#F69269"
			px={6}
			py={12}
			gap={8}
			display={
				display === 'desktop'
					? { base: 'none', lg: 'flex' }
					: { base: 'flex', lg: 'none' }
			}
			mt={display === 'mobile' ? 8 : 0}
		>
			<Heading as="h2" color={theme.palette.primary} fontSize={'30px'}>
				{title}
			</Heading>
			{content && <Box fontSize={'24px'}>{content}</Box>}
			<Box>
				<Button variant="cta" size="lg" as={Link} href={buttonLink}>
					{frontendLink(buttonLabel)}
				</Button>
			</Box>
		</VStack>
	);
};

export default PostFeed;
