import { GetPageQuery } from '../__generated__/graphql-old';
import { MenuItemProps } from '../types/Menu';

export function buildMenuTree(
	menuItems: GetPageQuery['primaryMenuItems']['nodes']
) {
	// Iterate through the menu items again to assign children to their respective parents
	const tree: MenuItemProps[] = [];
	menuItems.forEach(item => {
		if (item.parentId) {
			// If the item has a parentId, find the parent and add this item as a child
			const parent = tree.find(parent => parent.id === item.parentId);

			if (parent) {
				parent.children.push({
					id: item.id,
					uri: item.uri,
					label: item.label,
					children: [],
				});

				// Update Tree
				const index = tree.findIndex(
					parent => parent.id === item.parentId
				);
				tree[index] = parent;
			}
		} else {
			// If the item does not have a parentId, it is a root level item
			tree.push({
				id: item.id,
				uri: item.uri,
				label: item.label,
				children: [],
			});
		}
	});

	return tree;
}
