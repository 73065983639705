import { WordPressBlock } from '@faustwp/blocks';
import { AcfHeroBanner as AcfHeroBannerProps } from '../../__generated__/graphql-old';
import dynamic from 'next/dynamic';

const HeroBanner = dynamic(() => import('../../components/HeroBanner'));

const AcfHeroBanner: WordPressBlock<AcfHeroBannerProps> = props => {
	const {
		image,
		buttonLink,
		buttonText,
		content,
		taglineBottomText,
		taglineTopText,
		displayTaglineBelowContent,
		taglineElement,
	} = props.blockHeroBanner;

	const heroBannerProps = {
		image: {
			sourceUrl: image.node.sourceUrl,
			sizes: image.node.sizes,
			altText: image.node.altText,
			srcSet: image.node.srcSet,
			mediaDetails: {
				width: image.node.mediaDetails.width,
				height: image.node.mediaDetails.height,
			},
		},
		buttonLink:
			buttonLink && buttonLink.nodes.length > 0
				? buttonLink.nodes[0].uri
				: '',
		buttonText: buttonText,
		content: content,
		taglineTopText: taglineTopText,
		taglineBottomText: taglineBottomText,
		useChakraImage: false,
		displayTaglineBelowContent: displayTaglineBelowContent,
		taglineElement:
			taglineElement && taglineElement.length > 0
				? taglineElement[0]
				: 'h2',
		unoptimized: false,
	};

	if (!image) {
		return <></>;
	}
	return <HeroBanner {...heroBannerProps} />;
};

AcfHeroBanner.displayName = 'AcfHeroBanner';

export default AcfHeroBanner;
